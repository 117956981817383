<script>
import Swal from "sweetalert2";

export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    async handleLogout() {
      let mustLogout = false;

      await Swal.fire({
        title: "Confirmer la déconnexion ?",
        // text: "Le document semble déjà avoir été envoyé",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non, annuler",
        confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
        cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          mustLogout = true;
        }
      });

      if (!mustLogout) {
        return;
      }

      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      localStorage.removeItem("userdata");
      localStorage.removeItem("userid");

      this.$router.push("/login");
    },
  },
};
</script>

<template>
  <!-- start page title -->
  <div class="row mt-5">
    <div class="col-12">
      <button
        v-if="$route.path == '/'"
        @click.prevent="handleLogout"
        class="mt-3 btn btn-light d-flex justify-content-center align-items-center"
      >
        <i class="ri-arrow-left-fill me-2"></i>
        <span>Se déconnecter</span>
      </button>

      <div
        class="mt-3 page-title-box d-sm-flex align-items-center justify-content-between"
      >
        <h4 class="mb-sm-0">{{ title }}</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li
              class="breadcrumb-item"
              v-for="(item, index) in items"
              :key="index"
              :class="{ active: item[0] }"
            >
              <a href="javascript: void(0);">{{ item.text }}</a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
